<div class="header p-d-flex shadow-2">
    @if(isMobile !== false){
    <div class="p-mr-auto header-left">
        <p-button (onClick)="sideNavToggle()" icon="pi pi-bars" [text]="true"></p-button>

        <p-image (click)="goToDashboard()" class="cursor-pointer" src="assets/images/logo-light.svg" alt="Image"
            width="127" height="62" />

        <div class="md:hidden block flex gap-2">
            @if(userRole === 'REVIEWER' && from === 'dashboard'){
            <div class="flex align-items-center">
                <i class="pi pi-bell text-2xl text-primary cursor-pointer" pBadge
                    value="{{getUnreadNotificationCount()}}"
                    (click)="getUnreadNotificationCount() > 0 && menu.toggle($event);toggleNotification(menu);"></i>
            </div>
            <p-menu #menu [model]="notifications" [popup]="true" styleClass="w-full md:w-25rem overflow-auto h-30rem">
                <ng-template pTemplate="submenuheader" let-item>
                    <span class="font-medium text-sm ">Notifications</span>
                </ng-template>
                <ng-template pTemplate="item" let-item>
                    <div class="px-3 pt-3" (click)="markRead($event, item)">
                        <div class="flex flex-column gap-2 border-bottom-1 surface-border pb-3">
                            <div class="flex justify-content-between">

                                <div class="flex flex-column gap-2 w-8 as-end">
                                    <div class="flex flex-column text-black-alpha-80">
                                        <span [ngClass]="item.readMore ? 'white-space-nowrap' : ''"
                                            class="text-sm font-medium w-12 overflow-hidden text-overflow-ellipsis">{{
                                            item.subject }}</span>
                                        <span [ngClass]="item.readMore ? 'white-space-nowrap' : ''"
                                            class="text-sm w-12 overflow-hidden text-overflow-ellipsis">{{ item.message
                                            }}</span>
                                        <span class="text-xs font-medium text-primary as-end"
                                            (click)="readMore($event, item)">{{item.readMore ? 'Read More' : 'Read
                                            Less'}}</span>
                                    </div>
                                </div>

                                <div class="text-xs text-black-alpha-60 flex gap-1">
                                    <p-divider layout="vertical" styleClass="py-2" />
                                    <div class="flex flex-column ai-end">
                                        <span>{{item.createdAt | date:"MMMM dd YYYY" }}</span>
                                        <span class="font-bold">{{item.createdAt | date:"hh:mm a" }}</span>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </ng-template>
            </p-menu>
            }
            <p-button (onClick)="logout()" icon="pi pi-sign-out" [rounded]="true" [text]="true"></p-button>

        </div>

        @if(userRole === 'FIRM_MANAGER' && from === 'dashboard'){


        <app-multi-select (selectionChange)="searchMarketsSelected($event)" [selectedValues]="selectedSearchMarkets"
            optionLabel="name" optionValue="_id" [options]="searchMarkets">

        </app-multi-select>


        }

        @if(userRole === 'REVIEWER' && selectedTenant && selectedTenant.firm && from === 'dashboard'){
        <p-dropdown inputId="float-label" [(ngModel)]="selectedTenant" (onChange)="onChangeTenant($event.value)"
            [options]="reviewerTenants" optionLabel="firm.name">
            <ng-template let-tenant pTemplate="selectedItem">
                <div class="tenant-dropdown"><img alt="" class="firm-logo" [src]="tenant.logo" width="20" />{{
                    tenantNameForDropdown(tenant.firm.name) }}</div>
            </ng-template>
            <ng-template let-tenant pTemplate="item">
                <div class="tenant-dropdown"><img alt="" class="firm-logo" [src]="tenant.logo" width="20" />{{
                    tenantNameForDropdown(tenant.firm.name) }}</div>
            </ng-template>
        </p-dropdown>
        }
    </div>
    <div class="p-ml-auto header-right">
        <div class="wrap-button align-items-center">
            <div class="md:block hidden">
                @if(userRole === 'REVIEWER' && from === 'dashboard'){
                <div class="flex align-items-center">
                    <i class="pi pi-bell text-2xl text-primary cursor-pointer" pBadge
                        value="{{getUnreadNotificationCount()}}"
                        (click)="getUnreadNotificationCount() > 0 && menu.toggle($event);toggleNotification(menu);"></i>
                </div>
                <p-menu #menu [model]="notifications" [popup]="true"
                    styleClass="w-full md:w-25rem overflow-auto h-30rem">
                    <ng-template pTemplate="submenuheader" let-item>
                        <span class="font-medium text-sm ">Notifications</span>
                    </ng-template>
                    <ng-template pTemplate="item" let-item>
                        <div class="px-3 pt-3" (click)="markRead($event, item)">
                            <div class="flex flex-column gap-2 border-bottom-1 surface-border pb-3">
                                <div class="flex justify-content-between">

                                    <div class="flex flex-column gap-2 w-8 as-end">
                                        <div class="flex flex-column text-black-alpha-80">
                                            <span [ngClass]="item.readMore ? 'white-space-nowrap' : ''"
                                                class="text-sm font-medium w-12 overflow-hidden text-overflow-ellipsis">{{
                                                item.subject }}</span>
                                            <span [ngClass]="item.readMore ? 'white-space-nowrap' : ''"
                                                class="text-sm w-12 overflow-hidden text-overflow-ellipsis">{{
                                                item.message
                                                }}</span>
                                            <span class="text-xs font-medium text-primary as-end"
                                                (click)="readMore($event, item)">{{item.readMore ? 'Read More' : 'Read
                                                Less'}}</span>
                                        </div>
                                    </div>

                                    <div class="text-xs text-black-alpha-60 flex gap-1">
                                        <p-divider layout="vertical" styleClass="py-2" />
                                        <div class="flex flex-column ai-end">
                                            <span>{{item.createdAt | date:"MMMM dd YYYY" }}</span>
                                            <span class="font-bold">{{item.createdAt | date:"hh:mm a" }}</span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </ng-template>
                </p-menu>
                }

            </div>
            <p-button class="md:block hidden" (onClick)="logout()" icon="pi pi-sign-out" [rounded]="true"
                [text]="true"></p-button>
        </div>
        @if(userRole !== "REVIEWER" && userRole !== "ADMIN" && from === 'dashboard'){
        <div class="avatar lg:flex md:block hidden">
            <p-avatar [image]="firmLogo" styleClass="header-avatar" size="large" shape="circle"></p-avatar>
            <div class="details">
                <h6 class="m-0">{{firmName$ | async}}</h6>
                <p [pTooltip]="(firmAddress$ | async) || 'No address available'" tooltipPosition="top"
                    *ngIf="firmAddress$ | async as address">
                    <span *ngFor="let chunk of breakAddress(address)">
                        {{ chunk }}
                    </span>
                </p>

            </div>
        </div>
        }
    </div>
    }
    @else{

    <div class="m-auto header-left">
        <p-dropdown inputId="float-label" [(ngModel)]="selectedTenant" (onChange)="tenantSelected($event.value)"
            [options]="reviewerTenants" optionLabel="firm.name" />
    </div>
    }
</div>